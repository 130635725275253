/** @import: Main. */
@import "fonts";
@import "reset";

html {
  scroll-behavior: smooth;
}

#root,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

/** Body. */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Suvinil Sans";
}

main {
  background-color: white;
  overflow: hidden;
}

.modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

p {
  margin-bottom: 0 !important;
}

.modal-content {
  border-radius: 10px !important;
}

.container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;

  /** Mobile. */
  @media (max-width: 1366px) {
    max-width: 1200px;
  }
  @media (max-width: 1300px) {
    max-width: 1200px;
  }
}

@import "elements/preloader";
@import "elements/page-transition";
