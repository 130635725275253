@import "sass/vars.scss";

.modal-simulator {
  background-color: fade-out(black, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition-property: opacity, visibility;
  transition-duration: time(default);
  transition-timing-function: ease(inout);

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
  }

  &--wrapper {
    position: relative;
    width: 1045px;
    border-radius: 8px;
    overflow: hidden;
  }

  &--header {
    position: relative;
    background-size: cover;
    background-position: center center;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1px 35px 0;

    &_title {
      color: white;
      font-size: 22px;
      font-weight: bold;
    }
  }

  &--content {
    background-color: white;
    display: flex;
    align-items: flex-start;
    height: 580px;
    overflow: hidden;
    padding: 40px 25px 40px 35px;

    @media (max-height: 700px) {
      height: 530px;
      padding: 15px 25px 15px 35px;
    }

    .left {
      position: relative;
      width: 200px;
      flex-shrink: 0;

      &-search {
        width: 100%;
        margin-bottom: 25px;

        input {
          background-image: url("../../../../assets/icons/search-icon.svg");
          background-repeat: no-repeat;
          background-position: calc(100% - 17px) center;
          background-color: color(grey, background);
          color: color(grey, medium);
          font-size: 14px;
          display: block;
          width: 100%;
          height: 50px;
          padding: 0 50px 0 20px;
          border: none;
          border-radius: 8px;

          &::placeholder {
            color: color(grey, medium);
          }
        }
      }

      &-item {
        &:nth-child(2) {
          border-bottom: 1px solid #d8d8d8;
          padding-bottom: 25px;
          margin-bottom: 25px;
        }

        &--title {
          color: color(black);
          font-size: 14px;
          font-weight: bold;
        }

        &--listing {
          position: relative;
          margin-top: 15px;
          overflow: hidden;

          &::after {
            content: "";
            background: linear-gradient(to top, white, fade-out(white, 1));
            position: absolute;
            right: 7px;
            bottom: 0;
            left: 0;
            z-index: 2;
            height: 55px;
            pointer-events: none;
            transition-property: opacity, transform;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
          }

          &.scrolled::after {
            opacity: 0;
            transform: translateY(55px);
          }

          ul {
            position: relative;
            max-height: 120px;
            overflow: auto;
            z-index: 1;

            &::-webkit-scrollbar {
              width: 5px;
              border-radius: 60px;
              overflow: hidden;
            }

            &::-webkit-scrollbar-track {
              background: #f0f0f0;
            }

            &::-webkit-scrollbar-thumb {
              background: #ffd2b4;
              border-radius: 60px;
              overflow: hidden;
            }

            &::-webkit-scrollbar-thumb:hover {
              background: color(orange, base);
            }

            li {
              &:not(:last-child) {
                margin-bottom: 13px;
              }

              button {
                position: relative;
                color: color(grey, medium);
                font-size: 14px;
                font-weight: normal;
                padding: 4px 0 0 30px;
                transition-property: color;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);

                &::before {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 20px;
                  height: 20px;
                  border: 1px solid #333333;
                  transition-property: border-color;
                  transition-duration: time(fast);
                  transition-timing-function: ease(inout);
                }

                &::after {
                  content: url("../../../../assets/img/general/checkbox.svg");
                  position: absolute;
                  top: 3px;
                  left: 5px;
                  opacity: 0;
                  transition-property: opacity;
                  transition-duration: time(fast);
                  transition-timing-function: ease(inout);
                }
              }

              &:hover button {
                color: color(orange, base);

                &::before {
                  border-color: color(orange, base);
                }
              }

              &.active button {
                color: color(orange, base);

                &::before {
                  border-color: color(orange, base);
                }

                &::after {
                  opacity: 1;
                }
              }
            }
          }
        }
      }

      &-submit {
        position: relative;
        width: 100%;
        margin-top: 25px;

        button {
          width: 100%;
        }
      }
    }

    .right {
      position: relative;
      flex-grow: 1;
      margin-left: 35px;

      &-next {
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 5;
        border-radius: 60px;
        width: 170px;
        height: 36px;
        cursor: pointer;
        transition-property: background-color;
        transition-duration: time(default);
        transition-timing-function: ease(inout);

        &:hover {
          background-color: color(grey, background);
        }

        img {
          width: 22px;
        }

        span {
          color: black;
          font-size: 14px;
          font-weight: bold;
          display: inline-block;
          position: relative;
          bottom: -1px;
          margin-left: 10px;
        }
      }

      &-slide {
        position: relative;
        width: 100%;
        height: 335px;
        border-radius: 8px;
        overflow: hidden;
        transition-property: background-color;
        transition-duration: time(default);
        transition-timing-function: ease(inout);

        &--item {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
          opacity: 0;
          transition-property: opacity;
          transition-duration: time(default);
          transition-timing-function: ease(inout);
          pointer-events: none;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          &.active {
            opacity: 1;
            visibility: visible;
            z-index: 2;
          }
        }
      }

      &-content {
        display: flex;
        align-items: center;
        margin-top: 20px;

        &--icon {
          position: relative;
          flex-shrink: 0;
        }

        &--description {
          margin-left: 12px;
          flex-grow: 1;

          span {
            display: block;

            &:nth-child(1) {
              color: black;
              font-size: 16px;
              font-weight: bold;
              margin-bottom: 4px;
            }

            &:nth-child(2) {
              color: color(grey, medium);
              font-size: 12px;
              font-weight: normal;
              letter-spacing: -0.05em;
            }
          }
        }

        &--arrows {
          display: flex;
          align-items: center;

          button {
            &:nth-child(1) {
              margin-right: 40px;

              svg {
                transform: rotate(180deg);
                margin-right: 10px;
              }

              span {
                margin-right: 0;
              }
            }

            svg path {
              stroke: color(orange, base);
              transition-property: stroke;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
            }

            span {
              color: color(orange, base);
              font-size: 12px;
              font-weight: normal;
              display: inline-block;
              transition-property: color;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
              margin-right: 10px;
            }

            &:hover {
              svg path {
                stroke: color(grey, dark);
              }

              span {
                color: color(grey, dark);
              }
            }
          }
        }
      }

      &-listing {
        position: relative;
        display: grid;
        grid-template-columns: repeat(13, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        margin-top: 20px;

        &--item {
          position: relative;
          width: 100%;
          height: 40px;
          border-radius: 8px;
          overflow: hidden;
          cursor: pointer;

          &::after {
            content: "";
            position: absolute;
            background-image: url("../../../../assets/img/general/modal-simulator-verified.svg");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 20px 20px;
            border: 2px solid color(orange, base);
            border-radius: 8px;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
            transition-property: opacity;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
            z-index: 1;
          }

          &.active {
            &::after {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
