@import "sass/vars.scss";

// .transition-enter,
// .transition-exit {
//   transition-property: opacity;
//   transition-duration: time(default);
//   transition-timing-function: ease(inout);
// }

/** Main style. */
.transition-exit {
  position: relative;
  transition-property: opacity;
  transition-duration: time(default);
  transition-timing-function: ease(inout);
  z-index: 1;
  will-change: opacity;
  opacity: 0;
  transition-delay: 0.2s;
}

.transition-enter {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  opacity: 0;
  z-index: 3;

  &-done {
    position: relative;
    opacity: 1;
    transition: opacity 0.1s;
  }
}
