@import "sass/vars.scss";

#page-profile {
  .section-hero {
    position: relative;
    height: 250px;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 1.5s;
    transition-timing-function: ease(inout);

    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .wrapper {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      bottom: 50px;
      left: 0;
      z-index: 2;

      .content {
        text-align: center;
        width: 590px;
        margin: 0 auto;

        &-title {
          color: white;
          font-size: 24px;
          font-weight: bold;
        }
      }
    }
  }

  .section-account {
    padding: 50px 0 100px;

    .flex-wrapper {
      display: flex;
      align-items: flex-start;

      .left {
        width: 215px;
        flex-shrink: 0;
        margin-right: 85px;

        &-navigation {
          ul {
            li {
              opacity: 0;
              transform: translateX(10px);
              transition-property: opacity, transform;
              transition-duration: 1.5s;
              transition-timing-function: ease(inout);

              &:not(:last-child) {
                margin-bottom: 20px;
              }

              button {
                background-color: color(grey, background);
                color: color(grey, dark);
                font-size: 16px;
                font-weight: normal;
                width: 215px;
                height: 56px;
                text-align: left;
                padding: 1px 18px 0;
                border-radius: 4px;
                position: relative;
                overflow: hidden;
                transition-property: color;
                transition-duration: time(default);
                transition-timing-function: ease(inout);

                &::before {
                  content: "";
                  background-color: color(orange, base);
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  width: 4px;
                  opacity: 0;
                  transition-property: opacity;
                  transition-duration: time(default);
                  transition-timing-function: ease(inout);
                }
              }

              &:hover button,
              &.active button {
                color: color(orange, base);
              }

              &.active button::before {
                opacity: 1;
              }
            }
          }
        }
      }

      .right {
        position: relative;
        flex-grow: 1;
        opacity: 0;
        transition-property: height, opacity;
        transition-duration: time(fast), 1.5s;
        transition-timing-function: ease(inout);

        &.style-1 {
          height: 1088px;
        }

        &.style-2 {
          height: 521px;
        }

        .right-tab {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          opacity: 0;
          visibility: hidden;
          transform: translateY(5px);
          transition-property: opacity, visibility, transform;
          transition-duration: time(slow);
          transition-timing-function: ease(inout);

          &.active {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
            transition-delay: 0.3s;
            z-index: 2;
          }
        }

        .orders {
          &-title {
            color: color(black);
            font-size: 24px;
            font-weight: bold;
            letter-spacing: -0.03em;
            margin-bottom: 30px;
          }

          &-listing {
            .item {
              border: 1px solid #d8d8d8;
              border-radius: 8px;
              overflow: hidden;

              &:not(:last-child) {
                margin-bottom: 25px;
              }

              &-header {
                background-color: #04456b;
                display: flex;
                align-items: center;
                position: relative;
                width: 100%;
                height: 48px;
                padding: 0 20px;

                span {
                  color: white;
                  font-size: 12px;
                  font-weight: normal;
                  letter-spacing: -0.03em;

                  &:nth-child(2) {
                    margin-left: 40px;
                    flex-grow: 1;
                  }
                }

                button {
                  color: white;
                  font-size: 14px;
                  font-weight: bold;
                  letter-spacing: -0.03em;
                  position: relative;
                  bottom: -1px;
                  transition-property: color;
                  transition-duration: time(default);
                  transition-timing-function: ease(inout);

                  &:hover {
                    color: color(orange, base);
                  }
                }
              }

              &-bottom {
                display: flex;
                align-items: flex-start;
                height: 150px;
                padding: 21px 20px 0;

                &.style-2 {
                  padding: 15px 20px 0;
                }

                &--image {
                  position: relative;
                  width: 90px;
                  margin-right: 30px;
                  flex-shrink: 0;

                  &.style-2 {
                    width: 131px;
                  }

                  img {
                    width: 100%;
                  }
                }

                &--content {
                  width: 235px;
                  flex-shrink: 0;
                  margin-right: 60px;

                  span {
                    display: block;
                    letter-spacing: -0.03em;

                    &:nth-child(1),
                    &:nth-child(3) {
                      color: color(grey, medium);
                      font-size: 12px;
                      font-weight: normal;
                      margin-bottom: 5px;
                    }

                    &:nth-child(2) {
                      margin-bottom: 12px;
                    }

                    &:nth-child(2),
                    &:nth-child(4) {
                      color: color(black);
                      font-size: 16px;
                      font-weight: normal;
                    }

                    &:nth-child(5) {
                      color: color(grey, dark);
                      font-size: 16px;
                      font-weight: normal;
                      margin-top: 15px;
                    }
                  }
                }

                &--vendedor {
                  margin-right: 60px;

                  span {
                    display: block;
                    letter-spacing: -0.03em;

                    &:nth-child(1) {
                      color: color(grey, medium);
                      font-size: 12px;
                      font-weight: normal;
                      margin-bottom: 5px;
                    }

                    &:nth-child(2) {
                      color: color(black);
                      font-size: 16px;
                      font-weight: normal;
                    }
                  }
                }

                &--status {
                  flex-grow: 1;

                  span {
                    display: block;
                    letter-spacing: -0.03em;

                    &:nth-child(1) {
                      color: color(grey, medium);
                      font-size: 12px;
                      font-weight: normal;
                      margin-bottom: 5px;
                    }

                    &:nth-child(2) {
                      color: color(black);
                      font-size: 16px;
                      font-weight: normal;
                    }

                    &:nth-child(3) {
                      color: color(grey, dark);
                      font-size: 14px;
                      font-weight: normal;
                      margin-top: 10px;
                    }
                  }

                  button {
                    color: color(orange, base);
                    font-size: 12px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-transform: uppercase;
                    letter-spacing: 0.02em;
                    margin-top: 15px;

                    svg {
                      margin-left: 10px;
                      transition-property: transform;
                      transition-duration: time(default);
                      transition-timing-function: ease(inout);

                      path {
                        stroke: color(orange, base);
                      }
                    }

                    &:hover svg {
                      transform: translateX(10px);
                    }
                  }
                }
              }
            }
          }

          &-pagination {
            position: relative;
            margin: 25px 0 55px;
          }
        }

        .data {
          &-title {
            color: color(black);
            font-size: 16px;
            font-weight: normal;
            margin-bottom: 30px;
          }

          &-form {
            .field-group {
              position: relative;
              width: 310px;

              .placeholder {
                background-color: white;
                color: color(black);
                font-size: 14px;
                font-weight: normal;
                position: absolute;
                top: 18px;
                left: 10px;
                transform-origin: 0% 0%;
                pointer-events: none;
                padding: 0 8px;
                transition-property: transform;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);
                z-index: 2;
              }

              input {
                background-color: transparent;
                color: color(black);
                font-size: 14px;
                position: relative;
                width: 100%;
                height: 48px;
                padding: 0 18px;
                border: 1px solid #cccccc;
                border-radius: 8px;
                outline: none;
              }

              &.not-empty,
              &.focus {
                .placeholder {
                  transform: translate(5px, -22px) scale(0.7);
                }
              }
            }

            .form-line {
              display: flex;
              align-items: center;

              &:not(:last-child) {
                margin-bottom: 22px;
              }

              .field-group:first-child {
                margin-right: 50px;
              }

              .flex {
                display: flex;
                align-items: center;

                .field-group {
                  width: 145px;

                  &:first-child {
                    margin-right: 20px;
                  }

                  &:last-child {
                    margin-right: 50px;
                  }
                }
              }
            }

            .separator {
              color: color(black);
              font-size: 16px;
              font-weight: bold;
              margin: 45px 0 20px;
            }

            .form-submit {
              margin-top: 40px;
            }
          }
        }
      }
    }
  }

  &.transition-enter-done {
    .section-hero {
      opacity: 1;
      transition-delay: 0.3s;
    }

    .section-account .flex-wrapper {
      .left .left-navigation ul li {
        opacity: 1;
        transform: translateX(0);

        &:nth-child(1) {
          transition-delay: 0.5s;
        }

        &:nth-child(2) {
          transition-delay: 0.6s;
        }
      }

      .right {
        opacity: 1;
        transition-delay: 0s, 0.8s;
      }
    }
  }

  .section-account-products {
    border-bottom: 1px solid #cccccc;
    padding-bottom: 80px;

    .headline {
      margin-bottom: 35px;

      &-title {
        color: black;
        font-size: 24px;
        font-weight: bold;
      }
    }

    .flex-wrapper {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-column-gap: 20px;
    }
  }
}
