/** @list: Colors. */
$color: (
  black: #333333,
  blue: #04456b,
  orange: (
    base: #f66602,
    light: #f58c38,
    dark: #e64b0f,
  ),
  grey: (
    background: #f7f7f7,
    light: #cccccc,
    medium: #999999,
    dark: #666666,
  ),
);

/**
 * Returns a color from its name.
 *
 * @param  {string} $keys... Color names. Include multiple values for nested variations.
 * @return {string}          Color code.
 */
@function color($keys...) {
  $map: $color;
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}

/** @list: Transitions. */
$transitions: (
  sol: 0.3s,
  fast: 0.5s,
  default: 0.7s,
  slow: 1s,
);

/**
 * Returns a transition duration from a pre-defined speed.
 *
 * @param  {string} $speed Transition speed.
 * @return {Number}        Transition duration.
 */
@function time($speed) {
  @return map-get($transitions, $speed);
}

/** @list: Timing-functions. */
$timing_functions: (
  inout: cubic-bezier(0.5, 0, 0, 1),
  fade: cubic-bezier(0, 0, 0.3, 1),
);

/**
 * Returns a transition-timing-function setting from its type.
 *
 * @param  {string}   $type Timing-function type.
 * @return {function}       Timing-function setting.
 */
@function ease($type) {
  @return map-get($timing_functions, $type);
}
