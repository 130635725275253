@import "sass/vars.scss";

#page-checkout {
  .section-hero {
    position: relative;
    height: 250px;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 1.5s;
    transition-timing-function: ease(inout);

    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .wrapper {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      bottom: 35px;
      left: 0;
      z-index: 2;

      .content {
        text-align: left;
        width: 100%;
        margin: 0 auto;
        padding-left: 90px;

        &-title {
          color: white;
          font-size: 24px;
          font-weight: bold;
        }

        &-description {
          margin-top: 5px;

          p {
            color: white;
            font-size: 14px;
            font-weight: normal;
            line-height: 1.5;
            letter-spacing: -0.02em;
          }
        }
      }
    }
  }

  .section-checkout {
    padding: 65px 0 0;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 1.5s;
    transition-timing-function: ease(inout);

    .block {
      background: rgba(154, 173, 56, 0.08);
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      border: 2px solid #9aad38;
      border-radius: 8px;
      padding: 50px 60px;

      &-content {
        margin-top: 25px;

        &--title {
          display: flex;
          align-items: center;
          margin-bottom: 35px;

          span {
            color: #9aad38;
            font-size: 50px;
            font-weight: bold;
            display: inline-block;
            position: relative;
            bottom: -1px;
            margin-left: 20px;
          }
        }

        &--description {
          width: 460px;

          p {
            color: black;
            font-size: 16px;
            font-weight: normal;
            line-height: 1.5;
          }
        }
      }

      &-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &--title {
          color: black;
          font-size: 16px;
          font-weight: bold;
        }

        .item-block {
          position: relative;
          width: 370px;
          height: 215px;
          border-radius: 8px;
          overflow: hidden;
          margin-top: 15px;
          box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.17);

          &--image {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              transition-property: transform;
              transition-duration: time(slow);
              transition-timing-function: ease(inout);
            }
          }

          &--link {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 5;
            text-indent: -9999px;
          }

          &--play {
            position: absolute;
            right: 23px;
            bottom: 29px;
            transition-property: transform;
            transition-duration: time(slow);
            transition-timing-function: ease(inout);
            z-index: 2;
          }

          &--verified {
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 16px;
            position: absolute;
            top: 18px;
            left: 16px;
            width: 120px;
            height: 20px;
            z-index: 2;

            span {
              color: color(grey, dark);
              font-size: 11px;
              font-weight: bold;
              display: inline-block;
              letter-spacing: -0.05em;
              margin-left: 4px;
            }
          }

          &--title {
            color: white;
            font-size: 18px;
            font-weight: bold;
            position: absolute;
            left: 20px;
            bottom: 50px;
            z-index: 2;
          }

          &--time {
            color: white;
            font-size: 12px;
            font-weight: normal;
            position: absolute;
            left: 20px;
            bottom: 30px;
            z-index: 2;
          }

          &:hover {
            .item-block--play {
              transform: scale(0.9);
            }

            .item-block--image img {
              transform: scale(1.05);
            }
          }
        }
      }
    }
  }

  &.transition-enter-done {
    .section-hero {
      opacity: 1;
      transition-delay: 0.3s;
    }

    .section-checkout {
      opacity: 1;
      transition-delay: 0.5s;
    }
  }

  .section-banners {
    padding: 80px 0 60px;
  }
}
