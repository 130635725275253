@font-face {
  font-family: "Suvinil Sans";
  src: url("../assets/fonts/SuvinilSans-Light.woff2") format("woff2"),
    url("../assets/fonts/SuvinilSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Suvinil Sans";
  src: url("../assets/fonts/SuvinilSans-LightItalic.woff2") format("woff2"),
    url("../assets/fonts/SuvinilSans-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Suvinil Sans";
  src: url("../assets/fonts/SuvinilSans-Book.woff2") format("woff2"),
    url("../assets/fonts/SuvinilSans-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Suvinil Sans";
  src: url("../assets/fonts/SuvinilSans-BookItalic.woff2") format("woff2"),
    url("../assets/fonts/SuvinilSans-BookItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Suvinil Sans";
  src: url("../assets/fonts/SuvinilSans-Bold.woff2") format("woff2"),
    url("../assets/fonts/SuvinilSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Suvinil Sans";
  src: url("../assets/fonts/SuvinilSans-BoldItalic.woff2") format("woff2"),
    url("../assets/fonts/SuvinilSans-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}
