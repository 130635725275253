@import "sass/vars.scss";

.modal-compare {
  background-color: fade-out(black, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition-property: opacity, visibility;
  transition-duration: time(default);
  transition-timing-function: ease(inout);

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
  }

  &--wrapper {
    position: relative;
    width: 900px;
    border-radius: 8px;
    overflow: hidden;
  }

  &--header {
    position: relative;
    background-size: cover;
    background-position: center center;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1px 35px 0;

    &_title {
      color: white;
      font-size: 22px;
      font-weight: bold;
    }
  }

  &--content {
    background-color: white;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    height: 580px;
    overflow: hidden;
    padding: 30px 45px;

    @media (max-height: 700px) {
      height: 520px;
      padding: 15px 45px;
    }

    .product {
      .product-list {
        margin-top: 20px;

        &--item {
          display: flex;
          align-items: center;

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          &_icon {
            flex-shrink: 0;
            margin-right: 5px;
          }

          &_title {
            color: color(grey, medium);
            font-size: 14px;
            font-weight: normal;
            letter-spacing: -0.03em;
          }
        }
      }

      .product-cta {
        position: relative;
        width: 100%;
        display: block;
        margin-top: 20px;

        button {
          width: 100% !important;
          display: block;
        }
      }

      .product-change {
        display: flex;
        justify-content: center;
        margin-top: 10px;

        span {
          color: color(orange, base);
          font-size: 11px;
          font-weight: normal;
          display: inline-block;
          cursor: pointer;
        }
      }
    }
  }
}
