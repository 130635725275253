@import "sass/vars.scss";

#page-product {
  .section-hero {
    position: relative;
    width: 100%;
    padding: 65px 0 0;

    .background {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 280px;
      z-index: 1;
      transform-origin: 50% 0%;
      transform: scaleY(0);
      transition-property: transform;
      transition-duration: 1.5s;
      transition-timing-function: ease(inout);
    }

    .container {
      position: relative;
      z-index: 2;
    }

    .flex-wrapper {
      display: flex;
      align-items: flex-start;

      .left {
        width: 280px;
        flex-shrink: 0;

        &-select {
          position: relative;
          margin-bottom: 20px;
          opacity: 0;
          transition-property: opacity;
          transition-duration: 1.5s;
          transition-timing-function: ease(inout);

          &--title {
            color: white;
            font-size: 14px;
            font-weight: normal;
            letter-spacing: -0.05em;
            margin-bottom: 10px;
          }

          &--favorite {
            position: absolute;
            right: 10px;
            bottom: 10px;

            @keyframes favorite_product {
              0% {
                fill: white;
              }
              100% {
                fill: lighten(red, 5%);
              }
            }

            @keyframes favorite_product_scale {
              0%,
              100% {
                transform: scale(1);
              }
              70% {
                transform: scale(1.05);
              }
            }

            @keyframes favorite_product_scale2 {
              0% {
                transform: scale(1);
                opacity: 1;
              }
              100% {
                transform: scale(1.55);
                opacity: 0;
              }
            }

            button {
              position: relative;
              display: inline-block;

              svg path {
                fill: white;
              }

              svg:nth-child(2) {
                position: absolute;
                top: 0;
                left: 0;
              }
            }

            &.active button {
              svg,
              svg path {
                animation-fill-mode: forwards;
                animation-timing-function: ease(inout);
              }

              svg {
                &:nth-child(1) {
                  animation-name: favorite_product_scale;
                  animation-duration: time(fast);
                }

                &:nth-child(2) {
                  animation-name: favorite_product_scale2;
                  animation-duration: time(slow);
                }

                path {
                  animation-name: favorite_product;
                  animation-duration: time(slow);
                }
              }
            }
          }

          &--box {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 220px;
            height: 50px;
            cursor: pointer;
            padding: 0 22px 0 18px;
            border: 1px solid #ffffff;
            border-radius: 8px;

            span {
              color: white;
              font-size: 12px;
              font-weight: bold;
              padding: 0 10px;
              flex-grow: 1;
            }
          }
        }

        &-title {
          color: white;
          font-size: 30px;
          font-weight: bold;
          letter-spacing: -0.03em;
          padding-right: 30px;
          line-height: 1.4;
          opacity: 0;
          transition-property: opacity;
          transition-duration: 1.5s;
          transition-timing-function: ease(inout);
        }

        &-compare {
          margin-top: 5px;
          opacity: 0;
          transform: translateX(10px);
          transition-property: opacity, transform;
          transition-duration: 1.5s;
          transition-timing-function: ease(inout);

          button {
            color: white;
            font-size: 14px;
            font-weight: normal;
            letter-spacing: -0.05em;
            opacity: 0.7;
          }
        }

        &-sizes {
          margin-top: 45px;

          &--title {
            color: color(black);
            font-size: 14px;
            font-weight: normal;
            letter-spacing: -0.05em;
            margin-bottom: 10px;
            opacity: 0;
            transform: translateX(10px);
            transition-property: opacity, transform;
            transition-duration: 1.5s;
            transition-timing-function: ease(inout);
          }

          ul {
            display: flex;

            li {
              position: relative;
              width: 95px;
              height: 95px;
              opacity: 0;
              transform: translateX(10px);
              transition-property: opacity, transform;
              transition-duration: 1.5s;
              transition-timing-function: ease(inout);

              &:not(:last-child) {
                margin-right: 10px;
              }

              button {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                position: relative;
                width: 100%;
                height: 100%;
                border: 1px solid #cfcfcf;
                border-radius: 8px;
                transition-property: border-color;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);

                &::before {
                  content: "";
                  position: absolute;
                  bottom: -10px;
                  left: 50%;
                  transform: translateX(-50%);
                  width: 20px;
                  height: 20px;
                  border: 1px solid color(black);
                  border-radius: 50%;
                  background-color: white;
                  transition-property: border-color;
                  transition-duration: time(fast);
                  transition-timing-function: ease(inout);
                }

                &::after {
                  content: "";
                  background-color: color(orange, base);
                  position: absolute;
                  bottom: -5px;
                  left: 50%;
                  transform: translateX(-50%) scale(0);
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  transition-property: transform;
                  transition-duration: time(fast);
                  transition-timing-function: ease(inout);
                }

                svg path {
                  fill: color(black);
                  transition-property: fill;
                  transition-duration: time(fast);
                  transition-timing-function: ease(inout);
                }

                span {
                  color: color(black);
                  font-size: 12px;
                  font-weight: bold;
                  display: flex;
                  align-items: flex-start;
                  justify-content: center;
                  letter-spacing: -0.05em;
                  height: 28px;
                  margin-top: 7px;
                  transition-property: color;
                  transition-duration: time(fast);
                  transition-timing-function: ease(inout);
                }
              }

              &:nth-child(1) button svg {
                position: relative;
                right: -4px;
              }

              &:hover button {
                border-color: color(orange, base);

                &::before {
                  border-color: color(orange, base);
                }
              }

              &.active button {
                border-color: color(orange, base);

                &::before {
                  border-color: color(orange, base);
                }

                &::after {
                  transform: translateX(-50%) scale(1);
                }

                svg path {
                  fill: color(orange, base);
                }

                span {
                  color: color(orange, base);
                }
              }
            }
          }
        }

        &-calc {
          background-color: color(grey, background);
          padding: 20px 20px 25px 20px;
          width: 215px;
          border-radius: 8px;
          margin-top: 40px;
          opacity: 0;
          transition-property: opacity;
          transition-duration: 1.5s;
          transition-timing-function: ease(inout);

          &--title {
            color: black;
            font-size: 14px;
            font-weight: normal;
            line-height: 1.4;
            letter-spacing: -0.05em;
          }

          &--cta {
            margin-top: 20px;

            a {
              display: inline-flex;
              align-items: center;

              span {
                color: color(orange, base);
                font-size: 12px;
                font-weight: bold;
                display: inline-block;
                text-transform: uppercase;
                margin-right: 17px;
              }

              svg {
                position: relative;
                top: -1px;
                transition-property: transform;
                transition-duration: time(default);
                transition-timing-function: ease(inout);

                path {
                  stroke: color(orange, base);
                }
              }

              &:hover svg {
                transform: translateX(5px);
              }
            }
          }
        }
      }

      .center {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;
        opacity: 0;
        transition-property: opacity;
        transition-duration: 1.5s;
        transition-timing-function: ease(inout);

        &-circle {
          position: absolute;
          top: 365px;
          left: 50%;
          transform: translateX(-50%);
          pointer-events: none;
          z-index: 1;
        }

        &-product {
          position: relative;
          z-index: 2;
          width: 395px;

          img {
            max-width: 100%;
          }
        }

        &-navigation {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 31px;
          z-index: 4;

          &--arrow {
            position: relative;

            button {
              background-color: white;
              width: 22px;
              height: 22px;
              border-radius: 50%;
              overflow: hidden;
              display: inline-block;
            }
          }

          &--circle {
            background-color: white;
            position: relative;
            width: 31px;
            height: 31px;
            border: 4px solid color(orange, base);
            border-radius: 50%;
            margin: 0 21px;
          }
        }

        &-text {
          color: color(orange, base);
          font-size: 12px;
          font-weight: normal;
          letter-spacing: -0.03em;
          margin-top: 30px;
          text-align: center;
        }
      }

      .right {
        position: relative;
        width: 240px;
        flex-shrink: 0;

        &-distributors {
          position: relative;
          background-color: white;
          border: 1px solid #d8d8d8;
          border-radius: 8px;
          padding: 10px 0;
          overflow: hidden;
          opacity: 0;
          transform: translateY(10px);
          transition-property: opacity, transform;
          transition-duration: 1.5s;
          transition-timing-function: ease(inout);

          &::after {
            content: "";
            background: linear-gradient(to top, white, fade-out(white, 1));
            position: absolute;
            right: 7px;
            bottom: 0;
            left: 0;
            z-index: 2;
            height: 55px;
            pointer-events: none;
            transition-property: opacity, transform;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
          }

          &.scrolled::after {
            opacity: 0;
            transform: translateY(55px);
          }

          &--listing {
            position: relative;
            max-height: 220px;
            padding: 0 25px;
            overflow: auto;
            z-index: 1;

            &::-webkit-scrollbar {
              width: 5px;
              border-radius: 60px;
              overflow: hidden;
            }

            &::-webkit-scrollbar-track {
              background: #f0f0f0;
            }

            &::-webkit-scrollbar-thumb {
              background: #ffd2b4;
              border-radius: 60px;
              overflow: hidden;
            }

            &::-webkit-scrollbar-thumb:hover {
              background: color(orange, base);
            }

            .item {
              position: relative;
              padding-top: 10px;
              padding-left: 30px;

              &::before {
                content: "";
                position: absolute;
                top: 6px;
                left: 0;
                width: 20px;
                height: 20px;
                border: 1px solid color(black);
                border-radius: 50%;
                background-color: white;
                transition-property: border-color;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);
              }

              &::after {
                content: "";
                background-color: color(orange, base);
                position: absolute;
                top: 11px;
                left: 5px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                transform: scale(0);
                transition-property: transform;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);
              }

              &:not(:last-child) {
                border-bottom: 1px solid #d8d8d8;
                padding-bottom: 20px;
                margin-bottom: 10px;
              }

              &-button {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 5;
                text-indent: -9999px;
              }

              &-star {
                position: absolute;
                top: 7px;
                right: 0;
              }

              &-title {
                color: color(black);
                font-size: 12px;
                font-weight: normal;
                letter-spacing: -0.03em;
                transition-property: color;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);
              }

              &-description {
                margin: 5px 0 10px;

                p {
                  color: color(grey, medium);
                  font-size: 12px;
                  font-weight: normal;
                  line-height: 1.5;
                  letter-spacing: -0.03em;
                }
              }

              &-price {
                color: color(black);
                font-size: 14px;
                font-weight: bold;
                letter-spacing: -0.05em;
              }

              &:hover {
                &::before {
                  border-color: color(orange, base);
                }
              }

              &.active {
                &::before {
                  border-color: color(orange, base);
                }

                &::after {
                  transform: scale(1);
                }

                .item-title {
                  color: color(orange, base);
                }
              }
            }
          }
        }

        &-price {
          position: relative;
          background-color: white;
          border: 1px solid #d8d8d8;
          border-radius: 8px;
          padding: 15px 25px;
          margin-top: 20px;
          opacity: 0;
          transform: translateY(10px);
          transition-property: opacity, transform;
          transition-duration: 1.5s;
          transition-timing-function: ease(inout);

          &--title {
            color: color(grey, medium);
            font-size: 12px;
            font-weight: normal;
            line-height: 1.6;
            letter-spacing: -0.03em;
            margin-bottom: 20px;

            span {
              color: color(orange, base);
              display: block;
            }
          }

          &--value {
            color: color(black);
            font-size: 20px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 2px;
            letter-spacing: -0.03em;
            margin-bottom: 5px;
          }

          &--installment {
            color: color(grey, medium);
            font-size: 12px;
            font-weight: normal;
            letter-spacing: -0.03em;
          }

          &--count {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 100%;
            height: 50px;
            border: 1px solid #979797;
            border-radius: 8px;
            padding: 0 16px;
            overflow: hidden;
            margin-top: 20px;

            button {
              position: relative;
              display: inline-block;
              cursor: pointer;

              svg path {
                fill: color(orange, base);
                transition-property: fill;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);
              }

              &:hover svg path {
                fill: color(black);
              }
            }

            span {
              color: color(black);
              font-size: 14px;
              font-weight: bold;
              letter-spacing: -0.03em;
              text-align: center;
              display: inline-block;
              flex-grow: 1;
            }
          }

          &--cta {
            background-color: white;
            margin: 25px 0 -40px;

            a,
            button {
              width: 100%;
            }
          }
        }

        &-method-payment {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 50px;
          cursor: pointer;
          opacity: 0;
          transform: translateY(10px);
          transition-property: opacity, transform;
          transition-duration: 1.5s;
          transition-timing-function: ease(inout);

          span {
            color: color(orange, base);
            font-size: 12px;
            font-weight: normal;
            letter-spacing: -0.03em;
            margin-left: 10px;
          }
        }
      }
    }
  }

  .section-description {
    padding: 85px 0 95px;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 1.5s;
    transition-timing-function: ease(inout);

    .flex-wrapper {
      display: flex;
      align-items: flex-start;

      .column {
        &:nth-child(1) {
          width: 430px;
          margin-right: 70px;
        }

        &:nth-child(2) {
          flex-grow: 1;
        }

        &-title {
          color: color(black);
          font-size: 24px;
          font-weight: bold;
          padding-bottom: 15px;
          margin-bottom: 25px;
          border-bottom: 1px solid color(orange, base);
        }

        &-description {
          p {
            color: black;
            font-size: 14px;
            font-weight: normal;
            line-height: 1.5;
          }
        }

        &-text {
          margin-top: 25px;

          p {
            color: #979797;
            font-size: 14px;
            font-weight: normal;
            line-height: 1.5;
          }
        }

        &-list {
          li {
            color: black;
            font-size: 14px;
            font-weight: normal;
            line-height: 1.7;
            padding-left: 25px;
            position: relative;

            &:not(:last-child) {
              margin-bottom: 10px;
            }

            &::before {
              content: url("../../assets/img/general/list-style.svg");
              position: absolute;
              top: 1px;
              left: 0;
            }
          }
        }
      }
    }
  }

  &.transition-enter-done {
    .section-hero {
      .background {
        transform: scaleY(1);
        transition-delay: 0.1s;
      }

      .flex-wrapper {
        .left {
          &-select,
          &-title,
          &-compare,
          &-sizes .left-sizes--title,
          &-sizes ul li,
          &-calc {
            opacity: 1;
            transform: translate(0);
          }

          &-select {
            transition-delay: 0.5s;
          }

          &-title {
            transition-delay: 0.6s;
          }

          &-compare {
            transition-delay: 0.7s;
          }

          &-sizes {
            &--title {
              transition-delay: 0.8s;
            }

            ul li {
              &:nth-child(1) {
                transition-delay: 0.9s;
              }

              &:nth-child(2) {
                transition-delay: 1s;
              }
            }
          }

          &-calc {
            transition-delay: 1.1s;
          }
        }

        .center {
          opacity: 1;
          transition-delay: 1.3s;
        }

        .right {
          &-distributors,
          &-price,
          &-method-payment {
            opacity: 1;
            transform: translateY(0);
          }

          &-distributors {
            transition-delay: 1.5s;
          }

          &-price {
            transition-delay: 1.6s;
          }

          &-method-payment {
            transition-delay: 1.7s;
          }
        }
      }
    }

    .section-description {
      opacity: 1;
      transition-delay: 2s;
    }
  }

  .section-environments {
    .headline {
      margin-bottom: 25px;

      &-title {
        color: color(black);
        font-size: 24px;
        font-weight: bold;
        letter-spacing: -0.05em;
      }

      &-description {
        margin-top: 5px;

        p {
          color: color(black);
          font-size: 14px;
          font-weight: normal;
          line-height: 1.5;
        }
      }
    }

    .flex-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 30px;

      .item {
        position: relative;
        width: 100%;
        height: 280px;
        border-radius: 8px;
        cursor: pointer;
        overflow: hidden;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        &-image {
          position: relative;
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            user-select: none;
            pointer-events: none;
            transition-property: transform;
            transition-duration: time(slow);
            transition-timing-function: ease(inout);
          }
        }

        &-content {
          background-color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 16px;
          position: absolute;
          top: 18px;
          left: 16px;
          width: 150px;
          height: 20px;

          span {
            color: color(grey, dark);
            font-size: 11px;
            font-weight: bold;
            display: inline-block;
            letter-spacing: -0.05em;
            margin-left: 4px;
            position: relative;
            bottom: -1px;
          }
        }

        &:hover .item-image img {
          transform: scale(1.05);
        }
      }
    }
  }

  .section-banners {
    padding: 0;
  }
}
