@import "sass/vars.scss";

.not-responsive {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition-property: opacity;
  transition-duration: time(default);
  transition-timing-function: ease(inout);

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
  }

  span {
    color: color(black);
    font-size: 18px;
    font-weight: bold;
    line-height: 1.4;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
}
