@import "sass/vars.scss";

#page-cart {
  .section-general-products {
    .headline {
      opacity: 0;
      transform: translateX(10px);
      transition-property: opacity, transform;
      transition-duration: 1.5s;
      transition-timing-function: ease(inout);
    }

    .flex-wrapper {
      opacity: 0;
      transform: translateY(10px);
      transition-property: opacity, transform;
      transition-duration: 1.5s;
      transition-timing-function: ease(inout);
    }
  }

  .section-cart {
    padding: 10px 0 50px;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 1.5s;
    transition-timing-function: ease(inout);

    .headline {
      width: 1070px;
      margin: 0 auto 30px;

      &-title {
        color: color(black);
        font-size: 24px;
        font-weight: bold;
        letter-spacing: -0.05em;
      }
    }

    .flex-wrapper {
      display: flex;
      align-items: flex-start;
      width: 1070px;
      margin: 0 auto 30px;

      .left {
        width: 745px;
        flex-shrink: 0;

        &-header {
          display: flex;
          align-items: center;
          padding-bottom: 5px;
          border-bottom: 1px solid color(orange, base);

          &--item {
            color: color(black);
            font-size: 14px;
            font-weight: normal;
            letter-spacing: -0.05em;

            &:nth-child(1) {
              width: 300px;
              flex-shrink: 0;
            }

            &:nth-child(2),
            &:nth-child(3) {
              width: 150px;
              flex-shrink: 0;
              text-align: center;
            }

            &:nth-child(4) {
              flex-grow: 1;
              padding-left: 15px;
            }
          }
        }

        &-items {
          margin: 20px 0 50px;

          &--line {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            height: 135px;
            border: 1px solid #d8d8d8;
            border-radius: 8px;
            overflow: hidden;

            &:not(:last-child) {
              margin-bottom: 20px;
            }

            .item {
              &:nth-child(1) {
                display: flex;
                align-items: center;
                flex-shrink: 0;
                width: 300px;
                height: 135px;
                padding-left: 55px;

                &.no-headline {
                  padding-left: 35px;

                  .item-image {
                    margin-right: 20px;
                  }
                }

                .item-headline {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: absolute;
                  bottom: 30px;
                  left: -30px;
                  transform: rotate(-90deg);
                  width: 135px;
                  height: 75px;
                  flex-shrink: 0;
                  z-index: 1;

                  &--title {
                    color: white;
                    font-size: 12px;
                    font-weight: bold;
                    letter-spacing: -0.04em;
                    position: relative;
                    bottom: -1px;
                    margin-left: 10px;
                  }
                }

                .item-image {
                  position: relative;
                  width: 82px;
                  z-index: 2;

                  img {
                    width: 100%;
                  }
                }

                .item-content {
                  position: relative;
                  margin-left: 15px;
                  z-index: 2;

                  &--title {
                    color: color(black);
                    font-size: 14px;
                    letter-spacing: -0.03em;
                    line-height: 1.4;
                  }

                  &--salesman {
                    margin-top: 10px;

                    span {
                      letter-spacing: -0.03em;
                      display: block;

                      &:nth-child(1) {
                        color: color(grey, medium);
                        font-size: 12px;
                        font-weight: normal;
                        margin-bottom: 5px;
                      }

                      &:nth-child(2) {
                        color: color(black);
                        font-size: 12px;
                        font-weight: normal;
                      }
                    }
                  }
                }
              }

              &:nth-child(2),
              &:nth-child(3) {
                width: 150px;
                flex-shrink: 0;
              }

              &:nth-child(2) {
                overflow: hidden;
                padding: 0 10px;

                .item-count {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: relative;
                  height: 50px;
                  border: 1px solid #979797;
                  border-radius: 8px;
                  padding: 0 10px;

                  button {
                    position: relative;
                    display: inline-block;
                    cursor: pointer;

                    svg path {
                      fill: color(orange, base);
                      transition-property: fill;
                      transition-duration: time(fast);
                      transition-timing-function: ease(inout);
                    }

                    &:hover svg path {
                      fill: color(black);
                    }
                  }

                  span {
                    color: color(black);
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: -0.03em;
                    text-align: center;
                    display: inline-block;
                    flex-grow: 1;
                  }
                }
              }

              &:nth-child(3) {
                .item-description {
                  width: 100%;
                  text-align: center;

                  p {
                    color: color(grey, medium);
                    font-size: 12px;
                    font-weight: normal;
                    line-height: 1.5;
                    letter-spacing: -0.03em;

                    span {
                      color: color(grey, dark);
                      display: block;
                      line-height: 1.5;
                    }
                  }
                }
              }

              &:nth-child(4) {
                flex-grow: 1;
                padding-left: 15px;

                .item-price {
                  color: color(black);
                  font-size: 20px;
                  font-weight: bold;
                  letter-spacing: -0.03em;
                }
              }
            }
          }
        }

        &-freight {
          overflow: hidden;
          border: 1px solid #d8d8d8;
          border-radius: 8px;

          &--header {
            background-color: color(grey, background);
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            width: 100%;
            height: 72px;
            padding: 0 30px;

            > span {
              color: color(black);
              font-size: 14px;
              font-weight: normal;
              letter-spacing: -0.05em;
              position: relative;
              bottom: -1px;
            }

            label {
              color: color(orange, base);
              font-size: 14px;
              font-weight: normal;
              letter-spacing: -0.05em;
              position: relative;
              bottom: -2px;
              margin: 0;
              cursor: pointer;
              transition-property: color;
              transition-duration: time(default);
              transition-timing-function: ease(inout);

              &:hover {
                color: color(black);
              }
            }

            .freight {
              display: flex;
              align-items: center;

              input {
                background-color: transparent;
                color: color(grey, medium);
                font-size: 14px;
                font-weight: normal;
                border: 1px solid #cccccc;
                border-radius: 8px;
                width: 200px;
                height: 48px;
                outline: none;
                padding: 0 18px;
                margin-right: 5px;

                &::placeholder {
                  color: color(grey, medium);
                }
              }
            }
          }

          &--content {
            padding: 30px;

            form {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;

              .field-group {
                display: flex;
                align-items: center;
                justify-content: center;

                &:not(:last-child) {
                  margin-bottom: 15px;
                }

                input {
                  flex-shrink: 0;
                  appearance: none;
                  width: 20px;
                  height: 20px;
                  position: relative;
                  border: 1px solid #333333;
                  border-radius: 50%;
                  transition-property: border-color;
                  transition-duration: time(default);
                  transition-timing-function: ease(inout);

                  &::after {
                    content: "";
                    background-color: color(orange, base);
                    position: absolute;
                    top: 4px;
                    left: 4px;
                    width: 10px;
                    height: 10px;
                    transform: scale(0);
                    transition-property: transform;
                    transition-duration: time(default);
                    transition-timing-function: ease(inout);
                    border-radius: 50%;
                  }

                  &:checked {
                    border-color: color(orange, base);

                    &::after {
                      transform: scale(1);
                    }
                  }
                }

                label {
                  color: color(grey, black);
                  font-size: 14px;
                  font-weight: normal;
                  cursor: pointer;
                  position: relative;
                  bottom: -2px;
                  transition-property: color;
                  transition-duration: time(default);
                  transition-timing-function: ease(inout);
                  margin: 0 0 0 10px;

                  &:hover {
                    color: color(orange, base);
                  }
                }
              }
            }
          }
        }
      }

      .right {
        background-color: #f1f6fb;
        flex-grow: 1;
        margin-left: 40px;
        border-radius: 8px;
        padding: 30px 25px 0;

        &-title {
          color: color(black);
          font-size: 20px;
          font-weight: bold;
          letter-spacing: -0.05em;
          margin-bottom: 30px;
        }

        &-item,
        &-freight,
        &-total {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;

          span {
            letter-spacing: -0.03em;

            &:nth-child(1) {
              color: color(black);
              font-size: 14px;
              font-weight: normal;
            }

            &:nth-child(2) {
              color: color(black);
              font-size: 14px;
              font-weight: bold;
            }
          }
        }

        &-freight {
          margin-bottom: 0;
        }

        &-total {
          border-top: 1px solid #d8d8d8;
          padding-top: 25px;
          margin: 25px 0 10px;

          span:nth-child(2) {
            font-size: 20px;
          }
        }

        &-text {
          color: color(grey, medium);
          font-size: 12px;
          font-weight: normal;
          letter-spacing: -0.03em;
          text-align: right;
          margin-bottom: 25px;
        }

        &-cta {
          background-color: white;
          width: 155px;
          margin: 0 auto -24px;
          border-radius: 8px;
          transition-property: opacity;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          &.disabled {
            opacity: 0.5;
            pointer-events: none;
          }
        }
      }
    }
  }

  &.transition-enter-done {
    .section-general-products {
      .headline {
        opacity: 1;
        transform: translateX(0);
        transition-delay: 0.3s;
      }

      .flex-wrapper {
        opacity: 1;
        transform: translateY(0);
        transition-delay: 0.4s;
      }
    }

    .section-cart {
      opacity: 1;
      transition-delay: 0.5s;
    }
  }

  .modal-pay {
    background-color: fade-out(black, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition-property: opacity, visibility;
    transition-duration: 1.5s;
    transition-timing-function: ease(inout);

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 660px;
      height: 290px;
      transform: translate(-50%, -50%);
      border: 1px solid color(orange, base);
      border-radius: 14px;
      z-index: 1;
      opacity: 0;
      transition-property: opacity;
      transition-duration: 1.5s;
      transition-timing-function: ease(inout);
    }

    &--background {
      background-color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 640px;
      height: 270px;
      transform: translate(-50%, -50%);
      border-radius: 8px;
      overflow: hidden;
      z-index: 1;
      opacity: 0;
      transition-property: opacity;
      transition-duration: 1.5s;
      transition-timing-function: ease(inout);

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 40px);
        z-index: 1;
        width: 485px;
        height: 485px;
        background: radial-gradient(
          52.61% 52.61% at 50% 50%,
          #207bd6 0%,
          rgba(255, 255, 254, 0.02) 100%
        );
        opacity: 0;
        transition-property: opacity, transform;
        transition-duration: 2s;
        transition-timing-function: ease(inout);
      }
    }

    &--wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 640px;
      z-index: 2;
    }

    &--dots {
      @keyframes animation_dots_checkout {
        0%,
        100% {
          transform: scale(0);
        }

        40%,
        60% {
          transform: scale(1);
        }
      }

      &-1 {
        position: absolute;
        left: 40px;
        bottom: 40px;
        z-index: 2;
        display: flex;
        align-items: center;

        span {
          border: 1px solid #f2efe2;
          transform: scale(0);
          border-radius: 50%;

          &:not(:last-child) {
            margin-right: 20px;
          }

          &:nth-child(1) {
            background-color: #fdfdfb;
            width: 20px;
            height: 20px;
          }

          &:nth-child(2) {
            background-color: #faf9f3;
            width: 30px;
            height: 30px;
          }

          &:nth-child(3) {
            background-color: #f6f5ee;
            width: 36px;
            height: 36px;
          }

          &:nth-child(4) {
            background-color: #f2efe2;
            width: 43px;
            height: 43px;
          }
        }
      }

      &-2 {
        position: absolute;
        right: 40px;
        bottom: 40px;
        z-index: 2;
        display: flex;
        align-items: center;

        span {
          transform: scale(0);
          border-radius: 50%;

          &:not(:last-child) {
            margin-right: 20px;
          }

          &:nth-child(1) {
            background-color: #3b7bdc;
            width: 43px;
            height: 43px;
          }

          &:nth-child(2) {
            background-color: #89b0ea;
            width: 36px;
            height: 36px;
          }

          &:nth-child(3) {
            background-color: #c4d7f4;
            width: 30px;
            height: 30px;
          }

          &:nth-child(4) {
            background-color: #e2ebfa;
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    &--logo {
      position: relative;
      width: 94px;
      margin-top: -45px;
      opacity: 0;
      transition-property: opacity;
      transition-duration: 1.5s;
      transition-timing-function: ease(inout);
      z-index: 2;

      img {
        width: 100%;
      }
    }

    &--content {
      position: relative;
      margin: 15px 0 25px;
      opacity: 0;
      transition-property: opacity;
      transition-duration: 1.5s;
      transition-timing-function: ease(inout);
      z-index: 2;

      p {
        color: color(orange, base);
        font-size: 30px;
        font-weight: bold;
        line-height: 1.2;
        text-align: center;
        letter-spacing: -0.05em;
      }
    }

    &--product {
      position: relative;
      margin: 0 0 -40px;
      z-index: 2;
      opacity: 0;
      transform: translateY(10px);
      transition-property: opacity, transform;
      transition-duration: 1.5s;
      transition-timing-function: ease(inout);
    }

    &.active {
      opacity: 1;
      visibility: visible;
      pointer-events: initial;

      &::after {
        opacity: 1;
      }

      .modal-pay--background {
        opacity: 1;

        &::before {
          opacity: 1;
          transform: translate(-50%, 0);
          transition-delay: 1.5s;
        }
      }

      .modal-pay--dots-1 span,
      .modal-pay--dots-2 span {
        animation-name: animation_dots_checkout;
        animation-duration: 4s;
        animation-iteration-count: infinite;
      }

      .modal-pay--dots-1 span {
        &:nth-child(1) {
          animation-delay: 0.6s;
        }

        &:nth-child(2) {
          animation-delay: 0.8s;
        }

        &:nth-child(3) {
          animation-delay: 1s;
        }

        &:nth-child(4) {
          animation-delay: 1.2s;
        }
      }

      .modal-pay--dots-2 span {
        &:nth-child(1) {
          animation-delay: 1.2s;
        }

        &:nth-child(2) {
          animation-delay: 1s;
        }

        &:nth-child(3) {
          animation-delay: 0.8s;
        }

        &:nth-child(4) {
          animation-delay: 0.6s;
        }
      }

      .modal-pay--logo {
        opacity: 1;
        transition-delay: 0.3s;
      }

      .modal-pay--content {
        opacity: 1;
        transition-delay: 0.45s;
      }

      .modal-pay--product {
        opacity: 1;
        transform: translateY(0);
        transition-delay: 1s;
      }
    }
  }
}
