@import "sass/vars.scss";

@keyframes mask-play {
  from {
    -webkit-mask-position: 0% 0;
    mask-position: 0% 0;
  }
  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

#main-preloader {
  background-color: white;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 500;
  mask: url("../../assets/img/general/preloader.png");
  mask-size: 3000% 100%;
  transition-property: background-color;
  transition-duration: 2.5s;
  transition-timing-function: ease(inout);

  &.remove {
    background-color: color(orange, base);
    animation: mask-play 1.4s steps(29) forwards;
    animation-delay: 1s;
    transition-delay: 0.5s;
    pointer-events: none;

    .background-2 {
      animation: mask-play 1.4s steps(29) forwards;
      animation-delay: 0.9s;
    }

    .logo {
      opacity: 0;
      transform: translate(-50%, calc(-50% + 10px));
    }

    .count {
      opacity: 0;
    }
  }

  .background-1,
  .background-2 {
    display: none;
    background-color: white;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    mask: url("../../assets/img/general/preloader.png");
    mask-size: 3000% 100%;
  }

  .background-1 {
    animation: mask-play 1.4s steps(29) forwards;
    animation-delay: 0.3s;
  }

  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition-property: opacity, transform;
    transition-duration: 2.5s;
    transition-timing-function: ease(inout);
    z-index: 2;

    img {
      max-width: 200px;
    }
  }

  .count {
    color: color(black);
    font-size: 21px;
    font-weight: bold;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    transition-property: opacity;
    transition-duration: 2.5s;
    transition-timing-function: ease(inout);
    z-index: 2;
  }
}
