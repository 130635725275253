@import "sass/vars.scss";

#page-products {
  .section-hero {
    position: relative;
    height: 250px;
    z-index: 2;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 1.5s;
    transition-timing-function: ease(inout);

    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .wrapper {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      bottom: 50px;
      left: 0;
      z-index: 2;

      .content {
        text-align: center;
        width: 590px;
        margin-left: 150px;

        &-title {
          color: white;
          font-size: 24px;
          font-weight: bold;
        }

        &-description {
          margin-top: 15px;

          p {
            color: white;
            font-size: 14px;
            font-weight: normal;
            line-height: 1.5;
            letter-spacing: -0.02em;
          }
        }
      }
    }

    .block {
      position: absolute;
      right: 75px;
      bottom: -30px;
      width: 370px;
      height: 215px;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0px 14px 34px rgba(0, 1, 5, 0.38),
        0px -4px 34px rgba(237, 196, 196, 0.5);
      z-index: 3;
      opacity: 0;
      transform: translateX(10px);
      transition-property: opacity, transform;
      transition-duration: 1.5s;
      transition-timing-function: ease(inout);

      &-link {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 5;
        text-indent: -9999px;
      }

      &-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition-property: transform;
          transition-duration: time(slow);
          transition-timing-function: ease(inout);
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 0 110px 28px 22px;
        z-index: 2;

        &--title {
          color: white;
          font-size: 18px;
          font-weight: bold;
        }

        &--description {
          margin-top: 5px;

          p {
            color: white;
            font-size: 12px;
            font-weight: normal;
            line-height: 1.5;
            white-space: pre-wrap;
          }
        }
      }

      &-arrow {
        position: absolute;
        right: 30px;
        bottom: 30px;
        z-index: 3;
        transition-property: transform;
        transition-duration: time(default);
        transition-timing-function: ease(inout);

        svg path {
          stroke: color(orange, base);
        }
      }

      &:hover {
        .block-image img {
          transform: scale(1.05);
        }

        .block-arrow {
          transform: translateX(10px);
        }
      }
    }
  }

  .section-products {
    position: relative;
    padding: 100px 0 50px;
    z-index: 1;

    .flex-wrapper {
      display: flex;
      align-items: flex-start;

      .sidebar {
        position: relative;
        width: 220px;
        flex-shrink: 0;

        &-search {
          width: 100%;
          margin-bottom: 25px;
          opacity: 0;
          transform: translateY(10px);
          transition-property: opacity, transform;
          transition-duration: 1.5s;
          transition-timing-function: ease(inout);

          input {
            background-image: url("../../assets/icons/search-icon.svg");
            background-repeat: no-repeat;
            background-position: calc(100% - 17px) center;
            background-color: color(grey, background);
            color: color(grey, medium);
            font-size: 14px;
            display: block;
            width: 100%;
            height: 50px;
            padding: 0 50px 0 20px;
            border: none;
            border-radius: 8px;

            &::placeholder {
              color: color(grey, medium);
            }
          }
        }

        &-item {
          border-bottom: 1px solid #d8d8d8;
          margin-bottom: 0;
          padding-bottom: 25px;
          opacity: 0;
          transform: translateY(10px);
          transition-property: opacity, transform;
          transition-duration: 1.5s;
          transition-timing-function: ease(inout);

          &:not(:last-child) {
            margin-bottom: 25px;
          }

          &--title {
            color: color(black);
            font-size: 14px;
            font-weight: bold;
          }

          &--listing {
            position: relative;
            margin-top: 15px;
            overflow: hidden;

            &::after {
              content: "";
              background: linear-gradient(to top, white, fade-out(white, 1));
              position: absolute;
              right: 7px;
              bottom: 0;
              left: 0;
              z-index: 2;
              height: 55px;
              pointer-events: none;
              transition-property: opacity, transform;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
            }

            &.scrolled::after {
              opacity: 0;
              transform: translateY(55px);
            }

            ul {
              position: relative;
              max-height: 180px;
              overflow: auto;
              z-index: 1;

              &::-webkit-scrollbar {
                width: 5px;
                border-radius: 60px;
                overflow: hidden;
              }

              &::-webkit-scrollbar-track {
                background: #f0f0f0;
              }

              &::-webkit-scrollbar-thumb {
                background: #ffd2b4;
                border-radius: 60px;
                overflow: hidden;
              }

              &::-webkit-scrollbar-thumb:hover {
                background: color(orange, base);
              }

              li {
                &:not(:last-child) {
                  margin-bottom: 13px;
                }

                button {
                  position: relative;
                  color: color(grey, medium);
                  font-size: 14px;
                  font-weight: normal;
                  padding: 4px 0 0 30px;
                  transition-property: color;
                  transition-duration: time(fast);
                  transition-timing-function: ease(inout);

                  &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 20px;
                    height: 20px;
                    border: 1px solid #333333;
                    transition-property: border-color;
                    transition-duration: time(fast);
                    transition-timing-function: ease(inout);
                  }

                  &::after {
                    content: url("../../assets/img/general/checkbox.svg");
                    position: absolute;
                    top: 3px;
                    left: 5px;
                    opacity: 0;
                    transition-property: opacity;
                    transition-duration: time(fast);
                    transition-timing-function: ease(inout);
                  }
                }

                &:hover button {
                  color: color(orange, base);

                  &::before {
                    border-color: color(orange, base);
                  }
                }

                &.active button {
                  color: color(orange, base);

                  &::before {
                    border-color: color(orange, base);
                  }

                  &::after {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }

      .listing {
        flex-grow: 1;
        margin-left: 35px;

        &-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 30px;
          opacity: 0;
          transition-property: opacity;
          transition-duration: 1.5s;
          transition-timing-function: ease(inout);

          &--title {
            color: black;
            font-size: 24px;
            font-weight: bold;
            letter-spacing: -0.05em;
          }

          &--button {
            margin-top: 10px;

            button {
              display: flex;

              img {
                width: 9px;
                flex-shrink: 0;
              }

              span {
                color: color(grey, medium);
                font-size: 12px;
                font-weight: normal;
                margin-left: 7px;
                display: inline-block;

                strong {
                  color: color(orange, base);
                  font-weight: normal;
                }
              }
            }
          }
        }

        &-items {
          margin-bottom: 50px;
          opacity: 0;
          transform: translateY(10px);
          transition-property: opacity, transform;
          transition-duration: 1.5s;
          transition-timing-function: ease(inout);

          .grid-wrapper {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 23px;
            grid-row-gap: 50px;
          }

          .block {
            background-color: #f1f6fb;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 50px 0;
            padding: 35px;
            border-radius: 8px;

            &-content {
              margin-right: 40px;
              flex-shrink: 0;

              &--description {
                p {
                  color: color(black);
                  font-size: 20px;
                  font-weight: bold;
                  line-height: 1.2;
                  letter-spacing: -0.05em;
                }
              }

              &--cta {
                margin-top: 40px;

                a {
                  display: inline-flex;
                  align-items: center;

                  span {
                    color: color(black);
                    font-size: 12px;
                    font-weight: bold;
                    display: inline-block;
                    text-transform: uppercase;
                    margin-right: 17px;
                  }

                  svg {
                    position: relative;
                    top: -1px;
                    transition-property: transform;
                    transition-duration: time(default);
                    transition-timing-function: ease(inout);

                    path {
                      stroke: color(black);
                    }
                  }

                  &:hover svg {
                    transform: translateX(5px);
                  }
                }
              }
            }

            &-items {
              flex-grow: 1;
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              grid-column-gap: 30px;

              .item {
                position: relative;

                &-block {
                  position: relative;
                  height: 215px;
                  border-radius: 8px;
                  overflow: hidden;
                  box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.17);

                  &--image {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 1;

                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                      transition-property: transform;
                      transition-duration: time(slow);
                      transition-timing-function: ease(inout);
                    }
                  }

                  &--link {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 5;
                    text-indent: -9999px;
                  }

                  &--play {
                    position: absolute;
                    right: 23px;
                    bottom: 29px;
                    transition-property: transform;
                    transition-duration: time(slow);
                    transition-timing-function: ease(inout);
                    z-index: 2;
                  }

                  &--verified {
                    background-color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 16px;
                    position: absolute;
                    top: 18px;
                    left: 16px;
                    width: 120px;
                    height: 20px;
                    z-index: 2;

                    span {
                      color: color(grey, dark);
                      font-size: 11px;
                      font-weight: bold;
                      display: inline-block;
                      letter-spacing: -0.05em;
                      margin-left: 4px;
                    }
                  }

                  &--title {
                    color: white;
                    font-size: 18px;
                    font-weight: bold;
                    position: absolute;
                    left: 20px;
                    bottom: 50px;
                    z-index: 2;
                  }

                  &--time {
                    color: white;
                    font-size: 12px;
                    font-weight: normal;
                    position: absolute;
                    left: 20px;
                    bottom: 30px;
                    z-index: 2;
                  }

                  &:hover {
                    .item-block--play {
                      transform: scale(0.9);
                    }

                    .item-block--image img {
                      transform: scale(1.05);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.transition-enter-done {
    .section-hero {
      opacity: 1;
      transition-delay: 0.3s;

      .block {
        opacity: 1;
        transform: translateX(0);
        transition-delay: 0.5s;
      }
    }

    .section-products .flex-wrapper {
      .sidebar {
        &-search,
        &-item {
          opacity: 1;
          transform: translateY(0);
        }

        &-search {
          transition-delay: 0.6s;
        }

        &-item {
          transition-delay: 0.7s;
        }
      }

      .listing {
        &-header {
          opacity: 1;
          transition-delay: 0.8s;
        }

        &-items {
          opacity: 1;
          transform: translateY(0);
          transition-delay: 1s;
        }
      }
    }
  }

  .section-banners {
    padding-bottom: 80px;
  }
}
