@import "sass/vars.scss";

#page-home {
  .section-slide {
    position: relative;
    width: 100%;
    height: 415px;

    .slide {
      position: relative;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition-property: opacity;
      transition-duration: time(slow);
      transition-timing-function: ease(inout);

      &-items {
        background-color: white;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;

        .item {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
          opacity: 0;
          visibility: hidden;
          transition-property: opacity, visibility;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          &-image {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          &.active {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      &-navigation {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 135px;
        bottom: 85px;
        z-index: 2;

        .slide-arrow {
          button {
            position: relative;
            cursor: pointer;

            svg path {
              stroke: white;
              transition-property: stroke;
              transition-duration: time(fast);
              transition-timing-function: ease(inout);
            }

            &:hover svg path {
              stroke: color(orange, base);
            }
          }
        }

        .slide-dots {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 25px;

          .dot {
            position: relative;
            top: 1px;
            width: 6px;
            height: 6px;
            cursor: pointer;
            border: 1px solid white;
            transition-property: background-color, border-color;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
            text-indent: 999px;

            &:not(:last-child) {
              margin-right: 15px;
            }

            &:hover,
            &.active {
              background-color: color(orange, base);
              border-color: color(orange, base);
            }
          }
        }
      }
    }
  }

  .section-blocks {
    position: relative;
    margin-top: -50px;
    padding-bottom: 50px;
    z-index: 2;

    .flex-wrapper {
      padding: 0 60px;

      &:first-child {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 30px;
        margin-bottom: 65px;
      }

      &:last-child {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .item {
        position: relative;
        width: 100%;
        height: 215px;
        border-radius: 8px;
        overflow: hidden;
        opacity: 0;
        transform: translateY(10px);
        transition-property: opacity, transform;
        transition-duration: 1.5s;
        transition-timing-function: ease(inout);

        &:nth-child(1) {
          box-shadow: 0px 14px 34px rgba(154, 173, 56, 0.22);
        }

        &:nth-child(2) {
          box-shadow: 0px 14px 34px rgba(253, 229, 217, 0.7);
        }

        &:nth-child(3) {
          box-shadow: 0px 14px 34px rgba(0, 90, 160, 0.3);
        }

        &-link {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 5;
          text-indent: -9999px;
        }

        &-image {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition-property: transform;
            transition-duration: 1.5s;
            transition-timing-function: ease(inout);
          }
        }

        &-content {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          padding: 0 110px 28px 22px;
          z-index: 2;

          &--title {
            color: white;
            font-size: 18px;
            font-weight: bold;
          }

          &--description {
            margin-top: 5px;

            p {
              color: white;
              font-size: 12px;
              font-weight: normal;
              line-height: 1.5;
              white-space: pre-wrap;
            }
          }
        }

        &-arrow {
          position: absolute;
          right: 30px;
          bottom: 30px;
          z-index: 3;
          transition-property: transform;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          svg path {
            stroke: color(orange, base);
          }
        }

        &:hover {
          .item-image img {
            transform: scale(1.05);
          }

          .item-arrow {
            transform: translateX(10px);
          }
        }
      }

      .block {
        display: flex;
        align-items: center;
        opacity: 0;
        transform: translateY(10px);
        transition-property: opacity, transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);

        &-icon {
          position: relative;
          width: 72px;
          flex-shrink: 0;
          margin-right: 15px;

          img {
            width: 100%;
          }
        }

        &-content {
          &--title {
            color: color(black);
            font-size: 14px;
            font-weight: bold;
          }

          &--description {
            margin-top: 4px;

            p {
              color: color(grey, dark);
              font-size: 13px;
              font-weight: normal;
              line-height: 1.5;
              white-space: pre-wrap;
            }
          }
        }
      }
    }
  }

  &.transition-enter-done {
    .section-slide .slide {
      opacity: 1;
      transition-delay: 0.3s;
    }

    .section-blocks .flex-wrapper {
      .item {
        opacity: 1;
        transform: translateY(0);

        &:nth-child(1) {
          transition-delay: 0.5s;
        }

        &:nth-child(2) {
          transition-delay: 0.65s;
        }

        &:nth-child(3) {
          transition-delay: 0.8s;
        }
      }

      .block {
        opacity: 1;
        transform: translateY(0);

        &:nth-child(1) {
          transition-delay: 0.7s;
        }

        &:nth-child(2) {
          transition-delay: 0.85s;
        }

        &:nth-child(3) {
          transition-delay: 1s;
        }

        &:nth-child(4) {
          transition-delay: 1.05s;
        }
      }
    }
  }

  .section-best-sellers,
  .section-featured-products {
    .headline {
      margin-bottom: 35px;

      &-title {
        color: black;
        font-size: 24px;
        font-weight: bold;
      }

      &-button {
        margin-top: 10px;

        button {
          display: flex;

          img {
            width: 9px;
            flex-shrink: 0;
          }

          span {
            color: color(grey, medium);
            font-size: 12px;
            font-weight: normal;
            margin-left: 7px;
            display: inline-block;

            strong {
              color: color(orange, base);
              font-weight: normal;
            }
          }
        }
      }
    }

    .flex-wrapper {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-column-gap: 20px;
    }
  }

  .section-best-sellers {
    padding: 60px 0 50px;
  }

  .section-featured-products {
    padding: 50px 0 90px;

    .headline {
      text-align: center;

      &-button button {
        margin: 0 auto;
      }
    }
  }
}
