@import "sass/vars.scss";

#page-multi-step {
  .section-hero {
    position: relative;
    height: 250px;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 1.5s;
    transition-timing-function: ease(inout);

    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .wrapper {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      bottom: 95px;
      left: 0;
      z-index: 2;

      .content {
        text-align: center;
        margin: 0 auto;

        &-title {
          color: white;
          font-size: 24px;
          font-weight: bold;
        }
      }
    }
  }

  .section-steps {
    position: relative;
    margin-top: -30px;
    height: 530px;
    z-index: 2;
    opacity: 0;
    transition-delay: 0.5s;
    transition-property: height, opacity;
    transition-duration: time(default), 1.5s;
    transition-timing-function: ease(inout);

    > .container {
      position: relative;
      height: 530px;
      transition-delay: 0.5s;
      padding-top: 0;
      transition-property: height, padding-top;
      transition-duration: time(default);
      transition-timing-function: ease(inout);
    }

    &.finished {
      height: 1771px;

      > .container {
        height: 1771px;
        padding-top: 800px;
      }
    }

    .step-progress {
      position: absolute;
      bottom: 0;
      left: 15px;
      transition-duration: 1.5s;
      transition-property: opacity, visibility;
      transition-duration: 2s;
      transition-timing-function: ease(inout);
      z-index: 2;

      &.removed {
        transition-delay: 0s;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
      }

      &--line {
        background-color: color(grey, background);
        position: relative;
        width: 490px;
        height: 4px;
        border-radius: 4px;
        overflow: hidden;

        &::before {
          content: "";
          background-color: color(orange, base);
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          transition-property: width;
          transition-duration: time(default);
          transition-timing-function: ease(inout);
        }

        /** Variations. */
        &[data-progress="1"]::before {
          width: 12.5%;
        }
        &[data-progress="2"]::before {
          width: 25%;
        }
        &[data-progress="3"]::before {
          width: 37.5%;
        }
        &[data-progress="4"]::before {
          width: 50%;
        }
        &[data-progress="5"]::before {
          width: 62.5%;
        }
        &[data-progress="6"]::before {
          width: 75%;
        }
        &[data-progress="7"]::before {
          width: 87.5%;
        }
        &[data-progress="8"]::before {
          width: 100%;
        }
      }

      &--count {
        margin-top: 25px;
        padding-left: 20px;

        span {
          color: #cccccc;
          font-size: 20px;
          font-weight: bold;

          &:nth-child(1) {
            color: color(black);
          }

          &:not(:last-child) {
            margin-right: 1px;
          }
        }
      }

      &--back {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 4px;
        left: 80px;
        transition-property: opacity, visibility;
        transition-duration: time(fast);
        transition-timing-function: ease(inout);

        &.disabled {
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
        }

        svg path {
          stroke: color(grey, medium);
          transition-property: stroke;
          transition-duration: time(fast);
          transition-timing-function: ease(inout);
        }

        span {
          color: color(grey, medium);
          font-size: 12px;
          font-weight: bold;
          letter-spacing: -0.05em;
          position: relative;
          bottom: -1px;
          display: inline-block;
          margin-left: 10px;
          transition-property: color;
          transition-duration: time(fast);
          transition-timing-function: ease(inout);
        }

        &:hover {
          span {
            color: color(orange, base);
          }

          svg path {
            stroke: color(orange, base);
          }
        }
      }
    }

    .tabs {
      position: absolute;
      top: 0;
      right: 15px;
      left: 15px;
      transition-duration: 1.5s;
      transition-property: opacity, visibility;
      transition-duration: 2s;
      transition-timing-function: ease(inout);
      z-index: 1;

      &.removed {
        transition-delay: 0s;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
      }

      .tab-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition-property: opacity, visibility;
        transition-duration: time(default);
        transition-timing-function: ease(inout);

        &.active {
          opacity: 1;
          visibility: visible;
          pointer-events: initial;
        }

        .flex-wrapper {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          .left {
            width: 565px;
            height: 280px;
            flex-shrink: 0;

            &-title {
              .line {
                display: flex;
                align-items: center;
                height: 45px;
                overflow: hidden;

                &:not(:last-child) {
                  margin-bottom: 10px;
                }

                span {
                  color: color(black);
                  font-size: 40px;
                  font-weight: bold;
                  opacity: 0;
                  transform: translateY(50px);
                  transition-property: opacity, transform;
                  transition-duration: time(slow);
                  transition-timing-function: ease(inout);

                  &:not(:last-child) {
                    margin-right: 10px;
                  }
                }
              }
            }

            &-description {
              opacity: 0;
              transition-property: opacity;
              transition-duration: time(slow);
              transition-timing-function: ease(inout);

              p {
                color: color(black);
                font-size: 20px;
                font-weight: normal;
                letter-spacing: -0.03em;
                line-height: 1.3;
              }
            }
          }

          .right {
            position: relative;
            flex-grow: 1;

            &-list {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;

              button {
                color: color(black);
                font-size: 16px;
                font-weight: normal;
                text-align: center;
                border: 1px solid #d8d8d8;
                border-radius: 8px;
                padding: 15px 15px 14px;
                display: inline-block;
                margin-right: 25px;
                margin-bottom: 20px;
                opacity: 0;
                transform: translateX(10px);
                transition-property: border-color, background-color, opacity,
                  transform;
                transition-duration: time(fast), time(fast), time(slow),
                  time(slow);
                transition-timing-function: ease(inout);

                &:hover,
                &.active {
                  border-color: color(orange, base);
                }

                &.active {
                  background-color: #fdf2ea;
                }
              }
            }
          }
        }

        &.active {
          .left {
            &-title {
              .line span {
                opacity: 1;
                transform: translateY(0);

                @for $i from 0 to 20 {
                  &[data-index="#{$i}"] {
                    transition-delay: 0.05s * $i + 0.3s;
                  }
                }
              }
            }

            &-description {
              opacity: 1;
              transition-delay: 0.45s;
            }
          }

          .right {
            &-list button {
              opacity: 1;
              transform: translateX(0);

              @for $i from 0 to 10 {
                &:nth-child(#{$i}) {
                  transition-delay: 0s, 0s, 0.1s * $i + 0.6s, 0.1s * $i + 0.6s;
                }
              }
            }
          }
        }

        /** Variations. */
        &[data-index="0"] {
          .message {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            position: relative;
            height: 140px;
            margin-bottom: 65px;
            opacity: 0;
            transition-property: opacity;
            transition-duration: time(slow);
            transition-timing-function: ease(inout);

            &::before,
            &::after {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              border-radius: 8px;
              z-index: 1;
            }

            &::before {
              background-color: #f1f6fb;
              z-index: 2;
            }

            &::after {
              background-color: white;
              top: -25px;
              right: -25px;
              bottom: -25px;
              left: -25px;
              z-index: 1;
            }

            &-title {
              position: relative;
              color: color(black);
              font-size: 30px;
              font-weight: bold;
              margin-bottom: 12px;
              z-index: 3;
            }

            &-description {
              position: relative;
              width: 540px;
              z-index: 3;

              p {
                color: color(black);
                font-size: 18px;
                font-weight: normal;
                line-height: 1.5;
              }
            }
          }

          .flex-wrapper .right .right-list button {
            &:nth-child(2),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7) {
              margin-right: 0;
            }
          }

          &.active .message {
            opacity: 1;
            transition-delay: 0.15s;
          }
        }

        &[data-index="1"] {
          padding-top: 20px;

          .flex-wrapper {
            align-items: center;

            .right {
              width: 565px;
              flex-grow: unset;

              &::before {
                content: "";
                background-color: white;
                position: absolute;
                top: -30px;
                right: -30px;
                bottom: -30px;
                left: -30px;
                border-radius: 9px;
                z-index: 1;
                opacity: 0;
                transition-property: opacity;
                transition-duration: time(slow);
                transition-timing-function: ease(inout);
              }

              .right-blocks {
                position: relative;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-column-gap: 25px;
                grid-row-gap: 25px;
                z-index: 2;

                .item {
                  cursor: pointer;
                  opacity: 0;
                  transform: translateY(10px);
                  transition-property: opacity, transform;
                  transition-duration: time(slow);
                  transition-timing-function: ease(inout);

                  &-image {
                    position: relative;
                    width: 170px;
                    height: 215px;
                    overflow: hidden;
                    border-radius: 9px;

                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                      transition-property: transform;
                      transition-duration: time(default);
                      transition-timing-function: ease(inout);
                    }

                    &:hover img {
                      transform: scale(1.05);
                    }
                  }

                  &-title {
                    color: color(black);
                    font-size: 16px;
                    font-weight: bold;
                    text-align: center;
                    margin-top: 8px;
                    transition-property: color;
                    transition-duration: time(default);
                    transition-timing-function: ease(inout);
                  }

                  &.active .item-title {
                    color: color(orange, base);
                  }
                }
              }
            }
          }

          &.active .flex-wrapper .right {
            &::before {
              opacity: 1;
              transition-delay: 0.45s;
            }

            .right-blocks .item {
              opacity: 1;
              transform: translateY(0);

              @for $i from 0 to 10 {
                &:nth-child(#{$i}) {
                  transition-delay: 0.1s * $i + 0.6s;
                }
              }
            }
          }
        }

        &[data-index="2"] {
          padding-top: 120px;

          .flex-wrapper {
            align-items: center;

            .left {
              &-description {
                width: 310px;
                margin-top: 10px;
              }
            }

            .right {
              width: 700px;
              flex-grow: unset;

              &-colors {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;

                .item {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  position: relative;
                  margin-bottom: 45px;
                  cursor: pointer;
                  opacity: 0;
                  transform: translateY(10px);
                  transition-property: opacity, transform;
                  transition-duration: time(slow);
                  transition-timing-function: ease(inout);

                  &::before {
                    content: "";
                    background-color: color(grey, background);
                    position: absolute;
                    top: -20px;
                    right: -20px;
                    bottom: -20px;
                    left: -20px;
                    opacity: 0;
                    transition-property: opacity;
                    transition-duration: time(default);
                    transition-timing-function: ease(inout);
                    z-index: 1;
                  }

                  &:not(:last-child) {
                    margin-right: 45px;
                  }

                  &:nth-child(5) {
                    margin-right: 0;
                  }

                  &:nth-child(6),
                  &:nth-child(7),
                  &:nth-child(8),
                  &:nth-child(9),
                  &:nth-child(10),
                  &:nth-child(11) {
                    margin-bottom: 0;
                  }

                  &:first-child {
                    .item-block {
                      border: 1px solid #cccccc;
                    }
                  }

                  &-block {
                    position: relative;
                    width: 70px;
                    height: 70px;
                    border-radius: 8px;
                    margin-bottom: 10px;
                    z-index: 2;
                  }

                  &-title {
                    color: color(black);
                    font-size: 16px;
                    font-weight: bold;
                    position: relative;
                    z-index: 2;
                    text-align: center;
                  }

                  &:hover,
                  &.active {
                    &::before {
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }

          &.active .flex-wrapper .right .right-colors .item {
            opacity: 1;
            transform: translateY(0);

            @for $i from 0 to 15 {
              &:nth-child(#{$i}) {
                transition-delay: 0.1s * $i + 0.45s;
              }
            }
          }
        }

        &[data-index="3"] {
          padding-top: 80px;

          .flex-wrapper {
            .right {
              width: 615px;
              flex-grow: unset;

              .right-blocks {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 65px;

                .item {
                  cursor: pointer;
                  opacity: 0;
                  transform: translateY(10px);
                  transition-property: opacity, transform;
                  transition-duration: time(slow);
                  transition-timing-function: ease(inout);

                  &-image {
                    position: relative;
                    width: 100%;
                    height: 380px;
                    overflow: hidden;
                    border-radius: 9px;

                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                      transition-property: transform;
                      transition-duration: time(default);
                      transition-timing-function: ease(inout);
                    }

                    &:hover img {
                      transform: scale(1.05);
                    }
                  }

                  &-title {
                    color: color(black);
                    font-size: 30px;
                    font-weight: bold;
                    text-align: center;
                    margin-top: 15px;
                    transition-property: color;
                    transition-duration: time(default);
                    transition-timing-function: ease(inout);
                  }

                  &.active .item-title {
                    color: color(orange, base);
                  }
                }
              }
            }
          }

          &.active .flex-wrapper .right .right-blocks .item {
            opacity: 1;
            transform: translateY(0);

            @for $i from 0 to 4 {
              &:nth-child(#{$i}) {
                transition-delay: 0.1s * $i + 0.45s;
              }
            }
          }
        }

        &[data-index="4"] {
          .flex-wrapper {
            padding-top: 20px;

            .left {
              margin-top: 120px;
            }

            .right {
              width: 725px;
              flex-grow: unset;

              &::before {
                content: "";
                background-color: white;
                position: absolute;
                top: -30px;
                right: -30px;
                bottom: -30px;
                left: -30px;
                border-radius: 9px;
                z-index: 1;
                opacity: 0;
                transition-property: opacity;
                transition-duration: time(slow);
                transition-timing-function: ease(inout);
              }

              .right-blocks {
                position: relative;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-wrap: wrap;
                z-index: 2;

                .item {
                  position: relative;
                  cursor: pointer;
                  width: 160px;
                  margin-bottom: 25px;
                  opacity: 0;
                  transform: translateY(10px);
                  transition-property: opacity, transform;
                  transition-duration: time(slow);
                  transition-timing-function: ease(inout);

                  &:not(:last-child) {
                    margin-right: 15px;
                  }

                  &:nth-child(4) {
                    margin-right: 0;
                  }

                  &:nth-child(5),
                  &:nth-child(6),
                  &:nth-child(7) {
                    margin-bottom: 0;
                  }

                  &-image {
                    position: relative;
                    height: 190px;
                    overflow: hidden;
                    border-radius: 9px;

                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                      transition-property: transform;
                      transition-duration: time(default);
                      transition-timing-function: ease(inout);
                    }

                    &:hover img {
                      transform: scale(1.05);
                    }
                  }

                  &-title {
                    color: color(black);
                    font-size: 16px;
                    font-weight: bold;
                    text-align: center;
                    line-height: 1.5;
                    margin-top: 15px;
                    transition-property: color;
                    transition-duration: time(default);
                    transition-timing-function: ease(inout);
                  }

                  &.active .item-title {
                    color: color(orange, base);
                  }
                }
              }
            }
          }

          &.active .flex-wrapper .right {
            &::before {
              opacity: 1;
              transition-delay: 0.45s;
            }

            .right-blocks .item {
              opacity: 1;
              transform: translateY(0);

              @for $i from 0 to 10 {
                &:nth-child(#{$i}) {
                  transition-delay: 0.1s * $i + 0.45s;
                }
              }
            }
          }
        }

        &[data-index="5"] {
          padding-top: 130px;

          .flex-wrapper {
            .right {
              width: 555px;
              flex-grow: unset;

              .right-blocks {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-column-gap: 15px;
                grid-row-gap: 25px;

                .item {
                  position: relative;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  cursor: pointer;
                  opacity: 0;
                  transform: translateY(10px);
                  transition-property: opacity, transform;
                  transition-duration: time(slow);
                  transition-timing-function: ease(inout);

                  &-image {
                    position: relative;
                    width: 112px;
                    height: 112px;
                    overflow: hidden;
                    border-radius: 9px;

                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                      transition-property: transform;
                      transition-duration: time(default);
                      transition-timing-function: ease(inout);
                    }

                    &:hover img {
                      transform: scale(1.05);
                    }
                  }

                  &-title {
                    color: color(black);
                    font-size: 16px;
                    font-weight: bold;
                    text-align: center;
                    line-height: 1.5;
                    margin-top: 15px;
                    transition-property: color;
                    transition-duration: time(default);
                    transition-timing-function: ease(inout);
                  }

                  &.active .item-title {
                    color: color(orange, base);
                  }
                }
              }
            }
          }

          &.active .flex-wrapper .right-blocks .item {
            opacity: 1;
            transform: translateY(0);

            @for $i from 0 to 10 {
              &:nth-child(#{$i}) {
                transition-delay: 0.1s * $i + 0.6s;
              }
            }
          }
        }

        &[data-index="6"] {
          padding-top: 150px;

          .flex-wrapper {
            .right {
              width: 580px;
              flex-grow: unset;

              .right-list {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 20px;
                grid-row-gap: 20px;

                button {
                  width: 100%;
                  margin: 0;
                }
              }
            }
          }
        }

        &[data-index="7"] {
          padding-top: 150px;

          .flex-wrapper {
            .right {
              width: 280px;
              flex-grow: unset;
              margin-right: 150px;

              .right-list {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                grid-row-gap: 20px;

                button {
                  width: 100%;
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }

    .result {
      background-color: white;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      padding: 55px 0;
      border-radius: 8px 8px 0 0;
      opacity: 0;
      visibility: hidden;
      transition-property: opacity, visibility;
      transition-duration: 2s;
      transition-timing-function: ease(inout);
      z-index: 3;

      &-title {
        color: color(black);
        font-size: 40px;
        font-weight: bold;
        text-align: center;
        display: block;
        width: 100%;
        opacity: 0;
        transform: translateY(10px);
        transition-property: opacity, transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);
      }

      &-block {
        position: relative;
        width: 945px;
        border: 1px solid #d8d8d8;
        border-radius: 8px;
        margin: 40px auto 0;
        padding: 70px 70px 40px;
        opacity: 0;
        transition-property: opacity;
        transition-duration: 1.5s;
        transition-timing-function: ease(inout);

        &--share {
          position: absolute;
          top: 27px;
          right: 27px;
          cursor: pointer;
          opacity: 0;
          transition-property: opacity;
          transition-duration: time(slow);
          transition-timing-function: ease(inout);
        }

        &--products {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-column-gap: 40px;
          margin-bottom: 40px;

          .product {
            opacity: 0;
            transform: translateY(10px);
            transition-property: opacity, transform;
            transition-duration: 1.5s;
            transition-timing-function: ease(inout);
          }
        }

        &--actions {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          opacity: 0;
          transition-property: opacity;
          transition-duration: time(slow);
          transition-timing-function: ease(inout);

          .save-result {
            width: 290px;

            button {
              width: 100%;
            }
          }

          .reset-result {
            display: flex;
            align-items: center;
            margin-top: 40px;

            img {
              transition-property: transform;
              transition-duration: 1.5s;
              transition-timing-function: ease(inout);
            }

            span {
              color: color(orange, base);
              font-size: 14px;
              font-weight: bold;
              text-transform: uppercase;
              letter-spacing: 0.08em;
              position: relative;
              bottom: -2px;
              margin-left: 8px;
            }

            &:hover img {
              transform: rotate(360deg);
            }
          }
        }
      }

      &.active {
        opacity: 1;
        visibility: visible;
        pointer-events: initial;
        transition-delay: 1s;

        .result-title {
          opacity: 1;
          transform: translateY(0);
          transition-delay: 1.3s;
        }

        .result-block {
          opacity: 1;
          transition-delay: 1.4s;

          &--share {
            opacity: 1;
            transition-delay: 1.6s;
          }

          &--products .product {
            opacity: 1;
            transform: translateY(0);

            &:nth-child(1) {
              transition-delay: 1.75s;
            }

            &:nth-child(2) {
              transition-delay: 1.9s;
            }

            &:nth-child(3) {
              transition-delay: 2.05s;
            }
          }

          &--actions {
            opacity: 1;
            transition-delay: 2.2s;
          }
        }
      }
    }

    .hidden-sections {
      padding-bottom: 50px;
      height: 0;
      overflow: hidden;
      opacity: 0;
      transition-property: opacity, height;
      transition-duration: 1.5s;
      transition-timing-function: ease(inout);

      &.active {
        opacity: 1;
        height: 971px;
        transition-delay: 1.5s;
      }

      .section-painters {
        padding-top: 50px;

        .headline {
          text-align: center;
        }
      }
    }
  }

  &.transition-enter-done {
    .section-hero {
      opacity: 1;
      transition-delay: 0.3s;
    }

    .section-steps {
      opacity: 1;
      transition-delay: 0s, 0.5s;
    }
  }
}
