@import "sass/vars.scss";

#page-get-inspired {
  .section-hero {
    position: relative;
    height: 250px;
    opacity: 0;
    transition-property: opacity;
    transition-duration: time(slow);
    transition-timing-function: ease(inout);

    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .wrapper {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding-top: 30px;
      z-index: 2;

      .content {
        text-align: center;
        width: 590px;
        margin: 0 auto;

        &-title {
          color: white;
          font-size: 24px;
          font-weight: bold;
        }

        &-description {
          margin-top: 15px;

          p {
            color: white;
            font-size: 14px;
            font-weight: normal;
            line-height: 1.5;
            letter-spacing: -0.02em;
          }
        }
      }
    }
  }

  .section-cards {
    position: relative;
    margin-top: -25px;
    padding-bottom: 50px;
    z-index: 2;

    .filter {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 25px;
      width: 950px;
      margin: 0 auto;

      &-left {
        opacity: 0;
        transform: translateX(10px);
        transition-property: opacity, transform;
        transition-duration: 1.5s;
        transition-timing-function: ease(inout);

        input {
          background-image: url("../../assets/icons/search-icon.svg");
          background-repeat: no-repeat;
          background-position: calc(100% - 17px) center;
          background-color: color(grey, background);
          color: color(grey, medium);
          font-size: 14px;
          display: block;
          width: 100%;
          height: 50px;
          padding: 0 50px 0 20px;
          border: none;
          border-radius: 8px;

          &::placeholder {
            color: color(grey, medium);
          }
        }
      }

      &-right {
        background-color: color(grey, background);
        display: flex;
        align-items: center;
        padding-left: 20px;
        border-radius: 8px;
        opacity: 0;
        transform: translateX(10px);
        transition-property: opacity, transform;
        transition-duration: 1.5s;
        transition-timing-function: ease(inout);

        &--label {
          color: color(grey, medium);
          font-size: 14px;
          font-weight: normal;
          margin-right: 16px;
        }

        &--group {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 35px;

          &:nth-child(2),
          &:nth-child(4) {
            width: 85px;
          }

          &:nth-child(3) {
            width: 100px;
          }

          &:not(:last-child) {
            margin-right: 20px;
          }

          span {
            color: color(orange, base);
            font-size: 14px;
            font-weight: bold;
            letter-spacing: -0.05em;
            display: inline-block;
          }
        }
      }
    }

    .flex-wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 20px;
      margin-top: 75px;

      .column {
        position: relative;
        width: 100%;

        .item {
          position: relative;
          width: 100%;
          border-radius: 8px;
          cursor: pointer;
          overflow: hidden;
          opacity: 0;
          transform: translateY(10px);
          transition-property: opacity, transform;
          transition-duration: 1.5s;
          transition-timing-function: ease(inout);

          &:not(:last-child) {
            margin-bottom: 20px;
          }

          &-image {
            position: relative;

            img {
              width: 100%;
              display: block;
              user-select: none;
              pointer-events: none;
              transition-property: transform;
              transition-duration: time(slow);
              transition-timing-function: ease(inout);
            }
          }

          &-content {
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 16px;
            position: absolute;
            top: 18px;
            left: 16px;
            width: 150px;
            height: 20px;

            span {
              color: color(grey, dark);
              font-size: 11px;
              font-weight: bold;
              display: inline-block;
              letter-spacing: -0.05em;
              margin-left: 4px;
              position: relative;
              bottom: -1px;
            }
          }

          &:hover .item-image img {
            transform: scale(1.05);
          }
        }
      }
    }

    .more {
      margin-top: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.transition-enter-done {
    .section-hero {
      opacity: 1;
      transition-delay: 0.3s;
    }

    .section-cards {
      .filter {
        &-left {
          opacity: 1;
          transform: translateX(0);
          transition-delay: 0.5s;
        }

        &-right {
          opacity: 1;
          transform: translateX(0);
          transition-delay: 0.65s;
        }
      }

      .flex-wrapper .column {
        .item {
          opacity: 1;
          transform: translateY(0);
        }

        &:nth-child(1) .item {
          &:nth-child(1) {
            transition-delay: 0.7s;
          }

          &:nth-child(2) {
            transition-delay: 1.1s;
          }

          &:nth-child(3) {
            transition-delay: 1.6s;
          }

          &:nth-child(4) {
            transition-delay: 2s;
          }

          &:nth-child(5) {
            transition-delay: 2.3s;
          }
        }

        &:nth-child(2) .item {
          &:nth-child(1) {
            transition-delay: 0.8s;
          }

          &:nth-child(2) {
            transition-delay: 1.3s;
          }

          &:nth-child(3) {
            transition-delay: 1.7s;
          }

          &:nth-child(4) {
            transition-delay: 2.1s;
          }

          &:nth-child(5) {
            transition-delay: 2.4s;
          }
        }

        &:nth-child(3) .item {
          &:nth-child(1) {
            transition-delay: 0.9s;
          }

          &:nth-child(2) {
            transition-delay: 1.4s;
          }

          &:nth-child(3) {
            transition-delay: 1.8s;
          }

          &:nth-child(4) {
            transition-delay: 2.2s;
          }

          &:nth-child(5) {
            transition-delay: 2.5s;
          }
        }

        &:nth-child(4) .item {
          &:nth-child(1) {
            transition-delay: 1s;
          }

          &:nth-child(2) {
            transition-delay: 1.5s;
          }

          &:nth-child(3) {
            transition-delay: 1.9s;
          }

          &:nth-child(4) {
            transition-delay: 2.3s;
          }

          &:nth-child(5) {
            transition-delay: 2.6s;
          }
        }
      }
    }
  }

  .section-consultancy {
    padding: 70px 0 30px;

    .block {
      position: relative;
      width: 100%;
      height: 215px;

      &-image {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;

        img {
          width: 100%;
        }
      }

      &-content {
        position: absolute;
        top: 50%;
        left: 100px;
        transform: translateY(-50%);
        z-index: 2;

        &--title {
          color: color(blue);
          font-size: 24px;
          font-weight: bold;
        }

        &--description {
          margin-top: 12px;

          p {
            color: color(blue);
            font-size: 14px;
            font-weight: normal;
            line-height: 1.5;
          }
        }

        &--cta {
          margin-top: 30px;

          a {
            display: inline-flex;
            align-items: center;

            span {
              color: color(blue);
              font-size: 12px;
              font-weight: bold;
              display: inline-block;
              text-transform: uppercase;
              margin-right: 17px;
            }

            svg {
              position: relative;
              top: -1px;
              transition-property: transform;
              transition-duration: time(default);
              transition-timing-function: ease(inout);

              path {
                stroke: color(blue);
              }
            }

            &:hover svg {
              transform: translateX(5px);
            }
          }
        }
      }
    }
  }
}
